<template>
    <div class="about container px-8 mx-auto flex justify-center items-center">
        <div class="pt-32 pb-32">
            <div class="max-w-xl">
                <h1 class="text-3xl mb-12">✍🏼 About Me.</h1>
                <p class="lato-light text-xl mb-6 leading-relaxed">My name is Jacob Hammerle. I am a software engineer at <a class="lato-regular hover:underline" href="https://www.cerkl.com" target="_blank">Cerkl</a> based in Cincinnati, Ohio. Throughout my professional software development career outside of Cerkl I have worked at <a class="lato-regular hover:underline" href="https://www.softbankrobotics.com/" target="_blank">Softbank Robotics</a>, <a class="lato-regular hover:underline" href="https://www.geaviation.com/" target="_blank">GE Aviation</a>, and <a class="lato-regular hover:underline" href="https://www.brandery.org/" target="_blank">The Brandery</a>. Along the way I have also assisted numerous small companies and started a company of my own.</p>

                <p class="lato-light text-xl mb-6 leading-relaxed">I graduated from Miami University where I studied Interactive Media Studies and Entrepreneurship. During my time in school I worked on a lot of small side projects, most significantly being my senior thesis. For my senior thesis I developed the minimal viable product of what later became <a class="lato-regular hover:underline" href="http://www.tryminute.com/" target="_blank">Minute</a>. Minute is a journal that calls you. The primary objective of the app is to create a frictionless experience that helps you jumpstart the healthy habit of journaling.</p>
                
                <p class="lato-light text-xl mb-6 leading-relaxed">I am passionate about software development and building clean, efficient, and readable code. The world of programming has and always will entice me because of the never ending opportunies to learn. This site was created to host both my current and future software creations.</p>

                <p class="lato-light text-xl mb-6 leading-relaxed">To get in touch, please send an email to <a class="lato-regular hover:underline" href="mailto:jacob@hammerle.us">jacob@hammerle.us</a>.</p>
            </div>
            <div class="cerkl_subscribe_container"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'about',
    data() {
        return {

        }
    }
}
</script>

<style>
</style>