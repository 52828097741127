<template>
  <div class="mx-auto h-screen flex justify-center items-center max-w-lg">
    <div class="text-center">
      <img class="m-auto rounded-full shadow-lg h-32" src="../assets/img/headshot.png">
      <h1 class="text-4xl pt-2">Jacob Hammerle</h1>
      <h2 class="lato-light sm:text-lg md:text-xl">Software Engineer <a class="lato-regular hover:underline" href="https://twitter.com/Cerkl" target="_blank">@Cerkl</a></h2>
      <div>
        <a href="https://github.com/jacobhammerle" target="_blank"><i class="fab fa-github hover:text-gray-700 text-3xl mt-6 p-2"></i></a>
        <a href="https://twitter.com/JacobHammerle" target="_blank"><i class="fab fa-twitter hover:text-gray-700 text-3xl mt-6 p-2"></i></a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {

  }
}
</script>
